import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { PageHeader } from '../components/PageHeader';
import PostList from '../components/Blog/PostList';
import { decodeEntities } from '../utils/helpers';
import { GlobalCta } from '../components/Acf/GlobalCta'

const Blog = (props) => {
  const { data, pageContext, location } = props;
  const { wordpressPost: page = [], allWordpressPost, categoryFilter, categories, wordpressWpSettings, siteSettings } = data;
  const { title, yoast, acf } = page ? page : { title: null, yoast: {}, acf: {} }
  const { showAuthor } = siteSettings.options
  const { wordpressUrl } = wordpressWpSettings
  const { title: siteTitle, date_format } = wordpressWpSettings;
  const { edges: posts } = allWordpressPost
  const featuredImage = categories.edges[0] && categories.edges[0].node.featured_media ? categories.edges[0].node.featured_media : ''
  return (
    <Layout location={location} wordpressUrl={wordpressUrl}>
      <SEO
        title={`${
          yoast.metaTitle ?
          yoast.metaTitle :
          `Blog | ${decodeEntities(siteTitle)}`
        }`}
        desc={yoast.metaDescription}
        yoast={yoast}
        location={location}
      />
      <PageHeader headerTitle="Blog" location={location} headerBackgroundImage={featuredImage} />
      <PostList
        posts={posts}
        title="Latest posts"
        pageContext={pageContext}
        categoryFilter={categoryFilter}
        siteMetadata={wordpressWpSettings}
        pathPrefix="/blog/"
        showAuthor={showAuthor}
        dateFormat={date_format}
      />
      <GlobalCta />
    </Layout>
  )
}

export default Blog

export const pageQuery = graphql`
  query IndexQuery($limit: Int!, $skip: Int!) {
    wordpressWpSettings {
      title
      wordpressUrl
      blogSlug
      siteUrl
      date_format
    }
    siteSettings: wordpressAcfOptions {
      options {
        showAuthor
      }
    },
    wordpressPost: wordpressPage(slug: {eq: "blog"}) {
      title
      wordpress_id
      yoast {
        metaTitle: title
        metaDescription: metadesc
        meta_robots_noindex
        meta_robots_nofollow
      }
    }
    categoryFilter: allWordpressPost {
      nodes {
        categories {
          wordpress_id
          id
          name
          slug
          path
          id
        }
      }
    }
    allWordpressPost(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
    categories: allWordpressCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          featured_image_url {
            source_url
          }
          count
        }
      }
    }
  }
`
